import {
  ActionIcon,
  Flex,
  NumberInput,
  Select,
  Stack,
  Text,
} from "@mantine/core";
import { UseListStateHandlers } from "@mantine/hooks";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  ComparisonOperatorEnum,
  LogicalOperatorEnum,
  RuleLogicType,
} from "../../types/state.type";

interface UpdateRuleProcessorProps {
  ruleLogic: RuleLogicType[];
  ruleLogicHandler: UseListStateHandlers<RuleLogicType>;
  timeseries: string[];
}

const HandleRuleLogic = ({
  ruleLogic,
  ruleLogicHandler,
  timeseries,
}: UpdateRuleProcessorProps) => {
  const [operator, setOperator] = useState<string | null>(null);
  const [referenceTimeSeries, setReferenceTimeSeries] = useState<string | null>(
    null
  );
  const [comparison, setComparison] = useState<string | null>(null);
  const [threshold, setThreshold] = useState<string | number | undefined>("");

  const handleAddRuleLogic = () => {
    if (!referenceTimeSeries) {
      toast.error("Please select a reference time series");
      return;
    }
    if (!comparison) {
      toast.error("Please select a comparison operator");
      return;
    }
    if (threshold == null || threshold === "") {
      toast.error("Please enter a threshold value");
      return;
    }
    if (!operator && ruleLogic.length > 0) {
      toast.error("Please select a logical operator");
      return;
    }
    if (operator && ruleLogic.length === 0) {
      setOperator(null);
    }
    ruleLogicHandler.append({
      timeSeries: referenceTimeSeries,
      comparisonOperator: comparison as ComparisonOperatorEnum,
      threshold: threshold as number,
      logicalOperator: operator as LogicalOperatorEnum,
    });

    setOperator(null);
    setReferenceTimeSeries(null);
    setComparison(null);
    setThreshold("");
  };

  const handleUpdateRuleLogic = (
    index: number,
    field: keyof RuleLogicType,
    value: string | number | null
  ) => {
    const updatedRule = { ...ruleLogic[index], [field]: value };
    ruleLogicHandler.setItem(index, updatedRule);
  };

  const handleRemoveRuleLogic = (index: number) => {
    if (index === 0 && ruleLogic.length > 1) {
      ruleLogicHandler.setItemProp(1, "logicalOperator", undefined);
    }
    ruleLogicHandler.remove(index);
  };

  return (
    <>
      <Text fw={500} size="sm">
        Add Logic Item
      </Text>
      {/* Section to add new rule logic */}
      <Flex
        mih={50}
        gap="md"
        justify="flex-start"
        align="flex-end"
        direction="row"
      >
        <ActionIcon size="lg" onClick={handleAddRuleLogic}>
          <IconPlus />
        </ActionIcon>
        <Select
          w={100}
          label="Operator"
          data={Object.values(LogicalOperatorEnum)}
          disabled={ruleLogic.length === 0}
          value={operator}
          onChange={setOperator}
        />
        <Select
          w={600}
          label="Reference Time Series"
          data={timeseries}
          value={referenceTimeSeries}
          onChange={setReferenceTimeSeries}
          searchable
        />
        <Select
          w={100}
          label="Comparison"
          data={Object.values(ComparisonOperatorEnum)}
          value={comparison}
          onChange={setComparison}
        />
        <NumberInput
          w={100}
          label="Threshold"
          value={threshold}
          onChange={setThreshold}
        />
      </Flex>

      {/* Section to display and edit existing rule logic */}
      <Stack mt="md">
        <Text fw={500} size="sm">
          Final Rule To Be Saved
        </Text>
        {ruleLogic.map((rule, index) => (
          <Flex key={index} gap="md" align="flex-end">
            {/* Remove Rule Logic */}
            <ActionIcon
              variant="subtle"
              color="red"
              size="lg"
              onClick={() => handleRemoveRuleLogic(index)}
            >
              <IconTrash />
            </ActionIcon>

            {/* Logical Operator Select */}
            {index > 0 && (
              <Select
                w={100}
                label="Operator"
                data={Object.values(LogicalOperatorEnum)}
                value={rule.logicalOperator || null}
                onChange={(value) =>
                  handleUpdateRuleLogic(index, "logicalOperator", value)
                }
              />
            )}

            {/* Reference Time Series Select */}
            <Select
              w={400}
              label="Reference Time Series"
              data={timeseries}
              value={rule.timeSeries || null}
              onChange={(value) =>
                handleUpdateRuleLogic(index, "timeSeries", value)
              }
              searchable
            />

            {/* Comparison Operator Select */}
            <Select
              w={100}
              label="Comparison"
              data={Object.values(ComparisonOperatorEnum)}
              value={rule.comparisonOperator || null}
              onChange={(value) =>
                handleUpdateRuleLogic(index, "comparisonOperator", value)
              }
            />

            {/* Threshold Number Input */}
            <NumberInput
              w={100}
              label="Threshold"
              value={rule.threshold || ""}
              onChange={(value) =>
                handleUpdateRuleLogic(index, "threshold", value as number)
              }
            />
          </Flex>
        ))}
      </Stack>
    </>
  );
};

export default HandleRuleLogic;
