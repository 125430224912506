export enum ComparisonOperatorEnum {
  Greater = ">",
  GreaterEqual = ">=",
  Equal = "=",
  SmallerEqual = "<=",
  Smaller = "<",
}

export enum LogicalOperatorEnum {
  And = "AND",
  Or = "OR",
}

export interface RuleLogicType {
  timeSeries: string;
  comparisonOperator: ComparisonOperatorEnum;
  threshold: number;
  logicalOperator?: LogicalOperatorEnum;
}


export interface AssignmentType {
  stateIdentifier: string;
  timeSeriesIdentifier: string;
  operator: ComparisonOperatorEnum;
  value: number;
}
