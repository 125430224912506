import { Button, Group, Loader, Paper, Select, Stack, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { useApiClient } from "../../ApiClientProvider";
import { BackendClient } from "../../generated";

const fetchAlltenants = (apiClient: BackendClient) =>
  apiClient.tenant.getListTenantsGet();

const UpdateDashboardLabel = () => {
  const apiClient = useApiClient();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [selectedTenant, setSelectedTenant] = useState<string | null>('');
  const [dashboardLabel, setDashboardLabel] = useState('');

  const tenant_cache_key = ["fetchAlltenants"];
  const { data, error, isLoading } = useSWR(tenant_cache_key, () => fetchAlltenants(apiClient));

  const updateLabel = async () => {
    try {
      if (!selectedTenant) throw new Error("Tenant is required");
      if (!dashboardLabel) throw new Error("State label is required");
      setIsUpdating(true);
      await apiClient.dashboard.updateLabelTenantsTenantIdLabelPut(
        selectedTenant,
        dashboardLabel
      );
      toast.success("Label updated successfully!");
      mutate(tenant_cache_key);
    } catch (error) {
      toast.error(`Failed to update label: ${error}`);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    if (data) {
      const tenant = data.find((tenant) => tenant.identifier === selectedTenant);
      setDashboardLabel(tenant?.dashboard_config?.label || "");
    }
  }, [data, selectedTenant]);


  if (error) return <p>Error: {error.message}</p>;
  if (isLoading || !data) return <Loader mt="lg" />;
  const labels = data.map((tenant) => tenant.identifier || "");

  return (
    <>
      <Paper withBorder p="md" radius="md">
        <Stack gap="md">
          <Select
            label="Tenant"
            placeholder="Select tenant"
            data={labels}
            value={selectedTenant}
            onChange={setSelectedTenant}
          />
          <TextInput
            label="Label"
            placeholder="Label"
            value={dashboardLabel}
            onChange={(event) => setDashboardLabel(event.currentTarget.value)} />
          <Group>
            <Button
              mt="md"
              disabled={!selectedTenant}
              onClick={updateLabel}
              loading={isUpdating}
            >
              Update
            </Button>
          </Group>
        </Stack>
      </Paper>
    </>
  );
};

export default UpdateDashboardLabel;
