import { Button, Group, Loader, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { useApiClient } from "../../ApiClientProvider";
import {
  BackendClient,
  DatasourceData,
  DescriptionData,
  HierarchyData,
  ModelData,
  PartData
} from "../../generated";
import useEnvironmentStore from "../../hooks/useEnvironmentStore";
import useExcelStore from "../../hooks/useExcelStore";
import EntityUpdatesTable from "../EntityUpdatesTable";

const fetchDashboardDifferences = async (
  apiClient: BackendClient,
  tenant_identifier: string,
  description: DescriptionData | undefined,
  parameters: DatasourceData[] | undefined,
  models: ModelData[] | undefined,
  parts: PartData[] | undefined,
  hierarchy: HierarchyData[] | undefined
) => {
  if (!description || !parameters || !models || !parts || !hierarchy) {
    console.error(
      "Description, parameters, models, parts, and hierarchy are required"
    );
    return;
  }
  try {
    const response =
      await apiClient.dashboard.differencesTenantsTenantIdDifferencesPost(
        tenant_identifier,
        {
          description,
          parameters,
          models,
          parts,
          hierarchy,
        }
      );
    return response;
  } catch (error) {
    throw error;
  }
};

const UpdateParameters = () => {
  const apiClient = useApiClient();
  const { sheetsData } = useExcelStore();
  const [loadingToDB, setLoadingToDB] = useState(false);
  const description = sheetsData.Description;
  const parameters = sheetsData.Parameter;
  const models = sheetsData.Model;
  const parts = sheetsData.Part;
  const hierarchy = sheetsData.Hierarchy;

  const tenant_identifier = description?.tenant_identifier || "";
  const cache_key = ["dashboard", tenant_identifier];

  const { data } = useSWR(
    tenant_identifier ? cache_key : null,
    () =>
      fetchDashboardDifferences(
        apiClient,
        tenant_identifier,
        description,
        parameters,
        models,
        parts,
        hierarchy,
      ),
    {
      shouldRetryOnError: false,
    }
  );

  const updateDatabase = async () => {
    if (!window.confirm("Confirm the update: Excel → Database")) return;
    try {
      if (!tenant_identifier) throw new Error("Tenant identifier is required");
      if (!parameters) throw new Error("Edge devices are required");

      setLoadingToDB(true);
      await apiClient.dataSource.updateListTenantsTenantIdDatasourcesPut(
        tenant_identifier,
        parameters
      );
      toast.success("Parameters updated successfully!");
      mutate(cache_key);
    } catch (error) {
      toast.error(`Failed to update parameters: ${error}`);
    } finally {
      setLoadingToDB(false);
    }
  };

  const isValid = data != null && data.datasources.update.length === 0;
  const hasUpdates = data != null && data.datasources.update.length > 0;

  const { setMaxActiveStep } = useEnvironmentStore();
  useEffect(() => {
    setMaxActiveStep(isValid);
  }, [isValid, setMaxActiveStep]);
  return (
    <>
      {!data && (
        <>
          <Text>Loading data sources differences...</Text>
          <Loader />
        </>
      )}
      {isValid && (
        <Text>There is no specific fields to update</Text>
      )}
      {hasUpdates && (
        <>
          <Text>Differences found for parameters:</Text>
          <EntityUpdatesTable updates={data.datasources.update} />
          <Group>

            <Button
              onClick={updateDatabase}
              loading={loadingToDB}>
              Update Database
            </Button>
          </Group>
        </>
      )}
    </>
  );
};

export default UpdateParameters;
