import { toast } from "react-toastify";
import { utils, WorkBook } from "xlsx";
import { create } from "zustand";
import { EnumSheetNames, ExcelState } from "../types/excel.type";
import {
  convertDatasourceSheet,
  convertDescriptionSheet,
  convertEdgeSheet,
  convertExclusionSheet,
  convertHierarchySheet,
  convertModelSheet,
  convertPartSheet,
} from "./utils/convertSheets";

const useExcelStore = create<ExcelState>((set) => ({
  id: null,
  pickedFile: null,
  path: null,
  name: null,
  workbook: null,
  sheetsData: {},
  isValid: false,
  validationMessages: [],
  setId: (id: string) => set({ id }),
  setPickedFile: (pickedFile: any) => set({ pickedFile }),
  setPath: (path: string) => set({ path }),
  setName: (name: string) => set({ name }),
  setMetadata: (data: Partial<ExcelState>) =>
    set((state) => ({ ...state, ...data })),
  setWorkbook: (workbook: WorkBook) => {
    set({ workbook });
    const sheetsData: Partial<ExcelState["sheetsData"]> = {};
    const sheetNames = Object.values(EnumSheetNames);
    const validationMessages: string[] = [];

    // Load and validate each sheet individually
    for (const sheetName of sheetNames) {
      const rawData = utils.sheet_to_json<string[]>(
        workbook.Sheets[sheetName],
        { header: 1 }
      );

      switch (sheetName) {
        case EnumSheetNames.Description:
          const { descriptionData, descriptionErrorMessage } =
            convertDescriptionSheet(rawData);
          sheetsData.Description = descriptionData;
          if (descriptionErrorMessage) {
            validationMessages.push(descriptionErrorMessage);
          }
          break;
        case EnumSheetNames.Edge:
          const { edgeData, edgeErrorMessage } = convertEdgeSheet(rawData);
          sheetsData.Edge = edgeData;
          if (edgeErrorMessage) {
            validationMessages.push(edgeErrorMessage);
          }
          break;
        case EnumSheetNames.Datasource:
          const { datasourceData, datasourceErrorMessage } =
            convertDatasourceSheet(rawData);
          sheetsData.Parameter = datasourceData;
          if (datasourceErrorMessage) {
            validationMessages.push(datasourceErrorMessage);
          }
          break;
        case EnumSheetNames.Model:
          const { modelData, modelErrorMessage } = convertModelSheet(rawData);
          sheetsData.Model = modelData;
          if (modelErrorMessage) {
            validationMessages.push(modelErrorMessage);
          }
          break;
        case EnumSheetNames.Part:
          const { partData, partErrorMessage } = convertPartSheet(rawData);
          sheetsData.Part = partData;
          if (partErrorMessage) {
            validationMessages.push(partErrorMessage);
          }
          break;
        case EnumSheetNames.Hierarchy:
          const { hierarchyData, hierarchyErrorMessage } =
            convertHierarchySheet(rawData);
          sheetsData.Hierarchy = hierarchyData;
          if (hierarchyErrorMessage) {
            validationMessages.push(hierarchyErrorMessage);
          }
          break;
        case EnumSheetNames.Exclusion:
          const { exclusionData, exclusionErrorMessage } =
            convertExclusionSheet(rawData);
          sheetsData.Exclusion = exclusionData;
          if (exclusionErrorMessage) {
            validationMessages.push(exclusionErrorMessage);
          }
          break;
        default:
          toast.error(`Unknown sheet name: ${sheetName}`);
          return;
      }
    }

    // Inter-sheet validation if all sheets are valid
    if (validationMessages.length === 0) {
      // Validate the Model sheet 1/2: Each feature in the Model Sheet must be in the Parameter sheet with Type = Feature or Mix
      const modelFeatures = sheetsData.Model?.map((model) => model.Feature);
      const parameterFeatures = sheetsData.Parameter?.filter(
        (parameter) => parameter.Type === "Feature" || parameter.Type === "Mix"
      ).map((parameter) => parameter.Identifier);
      if (modelFeatures && parameterFeatures) {
        const missingFeatures = modelFeatures.filter(
          (feature) => !parameterFeatures.includes(feature)
        );
        if (missingFeatures.length > 0) {
          validationMessages.push(
            `The Model sheet has undefined features: ${missingFeatures.join(
              ", "
            )}`
          );
        }
      }

      // Validate the Model sheet 2/2: Each condition 1 to condition 10 should be in the Parameter sheet with Type = Condition or Mix
      const modelConditions = sheetsData.Model?.reduce<string[]>(
        (acc, model) => {
          const conditions = Object.values(model)
            .slice(3, 13)
            .filter((value) => value);
          return [...acc, ...conditions];
        },
        []
      );
      const parameterConditions = sheetsData.Parameter?.filter(
        (parameter) =>
          parameter.Type === "Condition" || parameter.Type === "Mix"
      ).map((parameter) => parameter.Identifier);
      if (modelConditions && parameterConditions) {
        const missingConditions = modelConditions.filter(
          (condition) => !parameterConditions.includes(condition)
        );
        if (missingConditions.length > 0) {
          validationMessages.push(
            `The Model sheet has undefined conditions: ${missingConditions.join(
              ", "
            )}`
          );
        }
      }

      // Validate Hierarchy sheet 1/2: Each non-empty value in the Hierarchy sheet should be either a part or a model identifier
      const hierarchyValues: string[] = sheetsData.Hierarchy
        ? sheetsData.Hierarchy.flatMap((hierarchyItem) =>
            Object.values(hierarchyItem).filter((value) => value)
          )
        : [];
      const partIdentifiers = sheetsData.Part?.map((part) => part.Identifier);
      const modelIdentifiers = sheetsData.Model?.map(
        (model) => model.Identifier
      );
      if (partIdentifiers && modelIdentifiers) {
        const missingIdentifiers = hierarchyValues.filter(
          (value) =>
            !partIdentifiers.includes(value) &&
            !modelIdentifiers.includes(value)
        );
        if (missingIdentifiers.length > 0) {
          validationMessages.push(
            `The Hierarchy sheet has undefined identifiers: ${missingIdentifiers.join(
              ", "
            )}`
          );
        }
      }

      // Validate Hierarchy sheet 2/2: Each leaf node in the Hierarchy sheet should be a model identifier
      const hierarchyLeafValues: string[] = sheetsData.Hierarchy
        ? sheetsData.Hierarchy.map((hierarchyItem) =>
            Object.values(hierarchyItem)
              .reverse()
              .find((value) => value !== "")
          )
        : [];
      if (modelIdentifiers) {
        const missingIdentifiers = hierarchyLeafValues.filter(
          (value) => !modelIdentifiers.includes(value)
        );
        if (missingIdentifiers.length > 0) {
          validationMessages.push(
            `The Hierarchy sheet has non-model identifiers as leaf nodes: ${missingIdentifiers.join(
              ", "
            )}`
          );
        }
      }
    }

    // Validate and set workbook
    const valid = validationMessages.length === 0;
    set({
      isValid: valid,
      validationMessages: validationMessages,
    });

    if (!valid) {
      toast.error("Workbook is invalid.");
      return;
    }
    set({ sheetsData });
    toast.success("Workbook loaded successfully.");
  },
}));

export default useExcelStore;
