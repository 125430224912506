export const pickerParams = {
  sdk: "8.0",
  entry: {
    sharePoint: {
      byPath: {
        web: "https://aiomaticcom.sharepoint.com/sites/All",
        list: "Freigegebene Dokumente",
        folder: "Customer Care/Customer Onboardings",
      },
    },
  },
  authentication: {},
  messaging: {
    origin: process.env.REACT_APP_ORIGIN_URL as string,
    channelId: "27",
  },
  selection: {
    mode: "single",
  },
  typesAndSources: {
    mode: "files",
    pivots: {
      oneDrive: true,
      recent: true,
      sharedLibraries: true,
      shared: true,
      search: true,
    },
  },
};

export const baseUrl = "https://aiomaticcom-my.sharepoint.com/";
